<template>
  <div id="login_page">
    <p class="tips">如遇问题请拨打客服热线：<span class="color1">400-821-5247</span></p>
    <div class="login_box">
      <p class="login_title">四季教育学习管理中心</p>
      <div class="form_box">
        <p>用户登录</p>
        <el-form :model="loginForm"
                 :rules="ruleInline"
                 ref="loginForm"
                 class="login_form"
                 label-width="0px">
          <el-form-item prop="user"
                        class="form-item">
            <el-input placeholder="手机号/管理中心账号"
                      v-model="loginForm.user"
                      @input="() => loginForm.user = loginForm.user.trim()"
                      clearable>
            </el-input>
          </el-form-item>
          <el-form-item prop="pwd"
                        class="form-item">
            <el-input type="password"
                      placeholder="密码"
                      v-model="loginForm.pwd"
                      clearable>
            </el-input>
          </el-form-item>
          <div class="flexBtw">
            <el-checkbox v-model="checked">记住密码</el-checkbox>
            <p>
              <router-link to="/forgetPsw"
                           class="linkbtn">忘记密码</router-link>|<router-link to="/register"
                           class="linkbtn">注册新账号</router-link>
            </p>
          </div>
          <el-button class="submit_button"
                     type="primary"
                     @click="submitForm()"
                     :loading="loading">登&nbsp;录</el-button>
        </el-form>
      </div>
    </div>
    <!-- 二次登录验证 -->
    <el-dialog
      title="登录验证"
      :visible.sync="dialogVisiblelogin"
      width="400px" :close-on-click-modal="false" :close-on-press-escape="false" :show-close="false">
      <el-form :model="loginAgain"
                 :rules="ruleInline"
                 ref="loginAgain"
                 class="login_form"
                 label-width="0px">
        <el-form-item prop="tel"
                class="form-item">
            <el-input readonly
                      v-model="loginAgain.tel"
                      >
            </el-input>
        </el-form-item>
        <el-form-item prop="yzm"
                        class="msgbox">
            <el-input type="text"
                      placeholder="验证码"
                      v-model="loginAgain.yzm"
                      clearable>
            </el-input>
            <div class="sendmsg">
              <span>|&nbsp;</span>
              <el-button type="text"
                         :disabled="yamFlag"
                         @click.stop="sendMsg">{{yammsg}}</el-button>
            </div>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" :loading="loadingAgain" @click="loginAgainFn">登&nbsp;录</el-button>
      </span>
    </el-dialog>
  </div>

</template>

<script>
import md5 from 'js-md5';
import Cookies from "js-cookie";
import { smgValidate } from '../utils/util'
import { user_login,send_sms,user_login_dfa } from '@/api/user'
export default {
  data () {
    return {
      loginForm: {
        user: '', pwd: ''
      },
      loginAgain:{
        tel: '',
        yzm: '',
      },
      telapi:'',
      ruleInline: {
        user: [{
          validator: (rule, value, callback) => {
            if (value === '') {
              callback(new Error('请输入手机号/管理中心账号'));
            } else {
              callback();
            }
          },
          trigger: "blur",
        },
        ],
        pwd: [
          {
            validator: (rule, value, callback) => {
              if (value === '') {
                callback(new Error('请输入您的密码'));
              } else {
                callback();
              }
            },
            trigger: "blur",
          }
        ],
        yzm: smgValidate,
      },
      loading: false,
      checked: false,
      yammsg: '发送验证码',
      yamFlag: false,
      dialogVisiblelogin:false,
      timer:null,
      userid:'',
      loadingAgain: false
    }
  },
  created () {
    let stu_username = Cookies.get('stu_username') || null;
    if (stu_username) {
      this.loginForm = {
        user: Cookies.get('stu_username'),
        pwd: Cookies.get('stu_userpwd')
      }
      this.checked = true
    }
  },
  methods: {
    /**提交 */
    submitForm () {
      this.$refs['loginForm'].validate((valid) => {
        if (valid) {
          this.subfn(this.loginForm);
        }
      });
    },
    async subfn (form) {
      let prames = {
        user: form.user,
        pwd: md5(form.pwd),
      }
      this.loading = true;
      let res = await user_login(prames)
      if (res.flag == '1') {
        if (this.checked) {
          Cookies.set('stu_username', form.user, { expires: 30, });
          Cookies.set('stu_userpwd', form.pwd, { expires: 30, });
        } else {
          Cookies.remove('stu_username');
          Cookies.remove('stu_userpwd');
        }
        Cookies.set('stu_userId', res.datainfo.user_info[0].id, { expires: 30, });
        this.$store.commit('setUserInfo', JSON.stringify(res.datainfo));
        this.$message({
          message: '登录成功',
          type: 'success'
        });
        setTimeout(() => {
          this.$router.push('/class')
        }, 1000)
        this.loading = false;
      }else if(res.flag == '2'){
        this.loading = false;
        this.dialogVisiblelogin = true
        this.loginAgain.tel = res.datainfo.user_info[0].telphone
        this.telapi = res.datainfo.user_info[0].telphone_nostar
        this.userid = res.datainfo.user_info[0].id
      } else {
        this.$alert(res.msg, '提示', {
          confirmButtonText: '确定',
          type: 'error',
        });
        this.loading = false;
      }
    },
       //发送验证码
    async sendMsg () {
        let res = await send_sms({ tel: this.telapi, type: 'login' })
        if (res.flag == '1') {
          this.$message({
            message: '短信发送成功',
            type: 'success'
          });
          this.timeCountDown();
        } else {
          this.$alert(res.msg, '提示', {
            confirmButtonText: '确定',
            type: 'error',
          });
        }
    },
    //验证码倒计时
    timeCountDown () {
      var time = 60;
      this.timer = setInterval(() => {
        if (time == 0) {
          window.clearInterval(this.timer);
          this.yammsg = "发送验证码";
          this.yamFlag = false;
          return true;
        }
        this.yammsg = time + "S后再发送";
        time--;
        this.yamFlag = true;
        return false;
      }, 1000);
    }, 
    //二次登录验证
    async loginAgainFn(){
      let prames = {
        userid: this.userid,
        tel: this.telapi,
        yzm:this.loginAgain.yzm
      }
      this.loadingAgain = true;
      let res = await user_login_dfa(prames)
      if (res.flag == '1') {
        this.dialogVisiblelogin = false;
        Cookies.set('stu_userId', res.datainfo.user_info[0].id, { expires: 30, });
        this.$store.commit('setUserInfo', JSON.stringify(res.datainfo));
        this.$message({
          message: '登录成功',
          type: 'success'
        });
        setTimeout(() => {
          this.$router.push('/class')
        }, 1000)
        this.loadingAgain= false
      }else if(res.flag == '2'){
        this.$alert(res.msg, '提示', {
          confirmButtonText: '确定',
          type: 'error',
          callback: action => {
              this.$router.push('/changepswlogin')
          }
        })
        Cookies.set('stu_userId', res.datainfo.user_info[0].id, { expires: 30, });
        this.$store.commit('setUserInfo', JSON.stringify(res.datainfo));
        this.loadingAgain= false
      } else {
        this.$alert(res.msg, '提示', {
          confirmButtonText: '确定',
          type: 'error',
        });
        this.loadingAgain= false
      }
      
    }
  },
}
</script>
<style lang="scss" rel="stylesheet/scss" scoped>
@import "login.scss";
</style>
 <style lang="scss">
.login_box {
  .el-input__inner {
    border: 1px solid #6f72ff;
    color: #6f72ff;
    font-size: 16px;
    border-radius: 0;
  }
  .el-checkbox__label {
    color: #6f72ff;
    font-size: 16px;
  }
  .el-checkbox__input.is-checked .el-checkbox__inner {
    background-color: #6f72ff;
    border-color: #6f72ff;
  }
  .el-checkbox__label,
  .el-checkbox__input.is-checked + .el-checkbox__label {
    color: #6f72ff;
  }
  input::-webkit-input-placeholder {
    /* WebKit browsers */
    color: rgba(111, 114, 255, 0.5);
  }

  input::-moz-placeholder {
    /* Mozilla Firefox 19+ */
    color: rgba(111, 114, 255, 0.5);
  }

  input:-ms-input-placeholder {
    /* Internet Explorer 10+ */
    color: rgba(111, 114, 255, 0.5);
  }
}
</style>
